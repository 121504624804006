import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import router from './router';

import es from './locales/es.json';
import pt from './locales/pt.json';

// Configuración de Vue I18n
const i18n = createI18n({
  locale: 'pt', // Idioma predeterminado
  fallbackLocale: 'es',
  messages: {
    es,
    pt,
  },
});

const app = createApp(App);
app.use(router);
app.use(i18n); // Usar Vue I18n
app.mount('#app');
